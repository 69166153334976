<template>
  <form
    ref="epayform"
    accept-charset="UTF-8"
    action="https://paycenter.piraeusbank.gr/redirection/pay.aspx"
    method="POST"
    style="display: none"
  >
    <input v-model="epayParams.acquirerId" name="AcquirerId" type="hidden" />
    <input v-model="epayParams.merchantId" name="MerchantId" type="hidden" />
    <input v-model="epayParams.posId" name="PosID" type="hidden" />
    <input v-model="epayParams.username" name="User" type="hidden" />
    <input v-model="epayParams.lang" name="LanguageCode" type="hidden" />
    <input v-model="epayParams.merchantReference" name="MerchantReference" type="hidden" />
  </form>
</template>

<script>
export default {
  data() {
    return {
      epayParams: {},
    }
  },
  computed: {
    amount() {
      return this.$route.params.amount
    },
    subscription() {
      return this.$route.params.subscription
    },
  },
  mounted() {
    this.$store
      .dispatch('app-common/fetchEpayParam', { amount: this.amount, subscription: this.subscription })
      .then(response => {
        this.epayParams = response.data
        this.$nextTick(() => {
          this.$refs.epayform.submit()
        })
      })
  },
}
</script>
